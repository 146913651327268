.unauthenticated-layout.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    background-color: #f0f2f5;
}

.unauthenticated-layout .content {
    flex: 1 1;
}

.unauthenticated-layout .content .branding {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 44px;
    line-height: 44px;
    align-items: center;
    background-color: initial;
    margin-bottom: 40px;

    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 33px;
}

.unauthenticated-layout .content .branding .name {
    background-color: #000000;
    color: transparent;
    text-shadow: 0px 2px 3px rgba(255, 255, 255, 0.39);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.unauthenticated-layout .footer {
    text-align: center;
    margin: 48px 0 24px;
    padding: 0 16px;
}

.unauthenticated-layout .register, .unauthenticated-layout .login {
    padding: 32px 0;
}

.unauthenticated-layout .register h3, .unauthenticated-layout .login h3 {
    margin-bottom: 20px;
    font-size: 16px;
}

.unauthenticated-layout .register .submit, .unauthenticated-layout .login .submit {
    width: 50%;
}

.unauthenticated-layout .register .text-action, .unauthenticated-layout .login .text-action {
    float: right;
    line-height: 40px;
}

.unauthenticated-layout .login .action-buttons {
    display: flex;
    justify-content: space-evenly;
}
