@import '~antd/dist/antd.css';
@import '@ant-design/pro-components/dist/components.css';

#root {
    height: 100vh;
}

.tool_iframe_container {
    width: 100%;
    height: 770px;
}

.tool_iframe_container iframe {
    width: 100%;
    height: 100%;
    border: none;
}
